
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from "yup";
import yupLocaleTr from 'yup-locale-tr';
import { hideModal } from "@/core/helpers/dom";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { setCookie } from "typescript-cookie";
import { Integration, TrendyolCredentials } from "@/dtos/Integration";
import { IntegrationType } from "@/dtos/IntegrationType";
interface Step1 {
  phone: string;
  password: string;
}
interface Step2 {
  getirusername: string;
  getirpassword: string;
}

interface Step3 {
  supplierId: string;
  trendyolusername: string;
  trendyolpassword: string;
}

interface Step4 {
  yemeksepetiusername: string;
  yemeksepetipassword: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 { }

export default defineComponent({
  name: "create-app-modal",
  components: {
    Field,
    ErrorMessage,
  },

  setup() {
    const tybranchdata = ref();
    const _stepperObj = ref<StepperComponent | null>(null);
    const createAppRef = ref<HTMLElement | null>(null);
    const createAppModalRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const branchesGetir = ref();
    const branchesTy = ref();
    const token = ref();
    const branchindexGetir = ref();
    const selectedBranchIndex = ref<number | null>(null);
    const ysmenulist = ref();
    const pleaseWait = ref(false);
    const ysselectedmenu = ref();
    const branchindexTy = ref();
    const branchHideGetir = ref(false);
    const yshidemenu = ref(false);
    const branchesYs = ref();
    const branchindexYs = ref();
    const branchHideYs = ref(false);
    const trendyolbuttonhidden = ref(false);
    const randompassword = ref("");
    randompassword.value = Math.random().toString(36).slice(-6);

    const hide = ref(false);
    const store = useStore();
    const router = useRouter();
    const formData = ref<KTCreateApp>({
      phone: "",
      password: "",
      getirusername: "",
      getirpassword: "",
      supplierId: "",
      trendyolusername: "",
      trendyolpassword: "",
      yemeksepetiusername: "",
      yemeksepetipassword: "",
    });

    watch(selectedBranchIndex, (newIndex) => {
      if (newIndex !== null) {
        const selectedItem = branchesYs.value[newIndex];
        yshidemenu.value = true;
        branchHideYs.value = true;
        ysmenulist.value = selectedItem.Menus;
        branchindexYs.value = selectedItem.Id;
        ysselectedmenu.value = selectedItem.Menus[0].id;
      }
    });
    const tempdatas = ref();
    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        createAppRef.value as HTMLElement
      );
    });
    Yup.setLocale(yupLocaleTr);
    const createAppSchema = [
      Yup.object({
        phone: Yup.string().required().label("Cep Telefonu"),
        password: Yup.string().label("Parola"),
      }),
      Yup.object({
        getirusername: Yup.string().required().label("Getir Kullanıcı adı"),
        getirpassword: Yup.string().required().label("Getir Parolası"),
      }),
      Yup.object({
        supplierId: Yup.string().required().label("Satıcı Id"),
        trendyolpassword: Yup.string().required().label("Api Key"),
        trendyolusername: Yup.string().required().label("Api Secret"),
      }),
      Yup.object({
        yemeksepetiusername: Yup.string()
          .required()
          .label("Yemeksepeti Kullanıcı adı (Email)"),
        yemeksepetipassword: Yup.string()
          .required()
          .label("Yemeksepeti Parola"),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };
    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value++;

      _stepperObj.value.goNext();
    };


    const completeRegister = (name) => {
      let newphone: string = formData.value.phone;
      let postphone = newphone[0] == "0" ? newphone.slice(1) : newphone;

      const data: any = {
        token: token.value,
        username: postphone,
        password: formData.value.password,
      };
      let i = 1;
      data.integrations = [] as Integration[];
      if (formData.value.supplierId != "") {
        let tyIntegration: Integration =
        {
          id: i.toString(),
          integrationType: IntegrationType.Trendyol
        };
        const tyCredentials: TrendyolCredentials = {
          supplierId: formData.value.supplierId,
          apiKey: formData.value.trendyolpassword,
          apiSecret: formData.value.trendyolusername,
          selectedBranch: branchindexTy.value,
        };
        tyIntegration.credentials = tyCredentials;
        data.integrations.push(tyIntegration);
        i++;
      }
      if (formData.value.getirusername != "") {
        let gtIntegration: Integration = {
          id: i.toString(),
          integrationType: IntegrationType.Getir,
          credentials: {
            username: formData.value.getirusername,
            password: formData.value.getirpassword,
            selectedBranch: branchindexGetir.value
          }
        };
        data.integrations.push(gtIntegration);
        i++;
      }
      if (ysselectedmenu?.value && ysselectedmenu.value.toString() != "") {
        let ysIntegration: Integration = {
          id: i.toString(),
          integrationType: IntegrationType.YemekSepeti,
          credentials: {
            username: formData.value.yemeksepetiusername,
            password: formData.value.yemeksepetipassword,
            selectedBranch: branchindexYs.value,
            selectedMenuId: ysselectedmenu.value?.toString()
          }
        };
        data.integrations.push(ysIntegration);
        i++;
      }
      pleaseWait.value = true;
      store.dispatch(Actions.COMPLETE_REGISTER, data).then((response) => {
        console.log(response);
        if (response.status == 201) {
          Swal.fire({
            title: "Başarılı",
            text: "Kayıt başarılı",
            icon: "success",
            confirmButtonText: "Tamam",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then(() => {
            console.log(token.value);
            console.log(response.data.access_token);
            setCookie("access_token", response.data.access_token);
            setCookie("refresh_token", response.data.refresh_token);
            router.push({ name: "dashboard" });
          });
        } else {
          Swal.fire({
            title: "Hata",
            text: "Kayıt başarısız",
            icon: "error",
            confirmButtonText: "Tamam",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }
      });
    };

    const handleStep = handleSubmit(async (values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };
      if(pleaseWait.value)
        return;
      if (currentStepIndex.value === 0) {
        if (formData.value.password == "") {
          let newphone: string = formData.value.phone;

          if (/^5\d{9}$/.test(newphone)) {
            pleaseWait.value = true;
            await store
              .dispatch(Actions.Register_User, {
                phoneNumber: newphone,
              })
              .then((r) => {
                if (r.status == 201) {
                  hide.value = true;
                  token.value = r.data.token;
                }
              })
              .catch((e) => {
                Swal.fire({
                  title: "Hata",
                  text: "Kullanıcı zaten mevcut",
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }).finally(() => { pleaseWait.value = false; });
          }
          else {
            Swal.fire({
              title: "Hata",
              text: "Telefon numarası sadece 5 ile başlayabilir ve toplamda 10 haneli olmalıdır.",
              icon: "error",
              confirmButtonText: "Tamam",
            });

          }
        } else {
          let newphone: string = formData.value.phone;
          let postphone = newphone[0] == "0" ? newphone.slice(1) : newphone;
          pleaseWait.value = true;
          store
            .dispatch(Actions.VERIFY_PASSWORD, {
              phoneNumber: postphone,
              password: formData.value.password,
              token: token.value,
            })
            .then((r) => {
              if (r.status == 201) {
                nextStep();
              } else {
                Swal.fire({
                  title: "Hata",
                  text: "Şifre doğrulanamadı",
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            }).catch((e)=>{
              Swal.fire({
                  title: "Hata",
                  html: `Şifre doğrulanamadı.<br/> Şifre ${postphone} numaralı cep telefonunuza iletilmiştir ve 6 karakterli harf ve rakamlardan oluşur. `,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
            }).finally(()=>{pleaseWait.value = false});
        }

        /*
        await store
          .dispatch(Actions.Register_User, logindata)
          .then(async (r: any) => {
            console.log(r);
            if (r != false) {
            
              Swal.fire({
                title: "Hata",
                text: "Bu telefon numarası sistemde kayıtlıdır.",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            } else {
              hide.value = true;

              if (formData.value.password == "") {
                const code: any = {
                  phone: formData.value.phone,
                  code: randompassword.value,
                };
                await store.dispatch(Actions.Send_Code, code);
              } else {
                if (formData.value.password == randompassword.value) {
                  currentStepIndex.value++;

                  if (!_stepperObj.value) {
                    return;
                  }

                  _stepperObj.value.goNext();
                } else {
                  Swal.fire({
                    title: "Hata",
                    text: "Şifreler uyuşmuyor.",
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              }
            }
          });*/
      }
      if (currentStepIndex.value === 1) {
        if (branchindexGetir.value) {
          nextStep();
          return;
        }
        pleaseWait.value = true;
        await store
          .dispatch(Actions.GETIR_CHECK, {
            username: formData.value.getirusername,
            password: formData.value.getirpassword,
          })
          .then((r) => {
            if (r.status == 201) {
              branchesGetir.value = r.data;
              branchindexGetir.value = r.data[0].Id;
              branchHideGetir.value = true;
            } else {
              Swal.fire({
                title: "Hata",
                text: "Getir kullanıcı adı ve şifre hatalı",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          }).finally(() => { pleaseWait.value = false; });
      }
      if (currentStepIndex.value === 2) {
        if (branchindexTy.value) {
          nextStep();
          return;
        }
        if (formData.value.trendyolpassword != "") {
          const data = {
            supplierId: formData.value.supplierId,
            apiSecret: formData.value.trendyolusername,
            apiKey: formData.value.trendyolpassword,
          };
          pleaseWait.value = true;
          await store.dispatch(Actions.TRENDYOL_CHECK, data).then((r) => {
            if (r.status == 201) {
              branchesTy.value = r.data;
              branchindexTy.value = r.data[0].Id;
              trendyolbuttonhidden.value = true;
            } else {
              Swal.fire({
                title: "Hata",
                html: "Trendyol api bilgileri hatalı. Bu bilgileri trendyol panelindeki Hesabım altında yer alan Entegrasyon bilgileri sayfasından alabilirsiniz. Sayfaya gitmek için <a href=\"https://partner.trendyol.com/account/info?tab=integrationInformation\" target=\"_blank\">tıklayınız.</a>.",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          }).finally(() => { pleaseWait.value = false; });
        }
      }
      if (currentStepIndex.value === 3) {
        if (formData.value.yemeksepetipassword != "" && !ysselectedmenu.value) {
          pleaseWait.value = true;
          store
            .dispatch(Actions.YEMEKSEPETI_CHECK, {
              username: formData.value.yemeksepetiusername,
              password: formData.value.yemeksepetipassword,
            })
            .then((r) => {
              if (r.status == 201) {
                branchesYs.value = r.data;
                selectedBranchIndex.value = 0;
                branchHideYs.value = true;
              } else {
                Swal.fire({
                  title: "Hata",
                  html: "Yemeksepeti kullanıcı adı ve şifre hatalı. Bu kullanıcı bilgileri Portal Sayfasında kullandığınız kullanıcı adı ve paroladır. <br/><b>GOWEB parolası değildir.</b>",
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            }).finally(() => { pleaseWait.value = false; });;
        } else if (formData.value.yemeksepetipassword != "" && ysselectedmenu.value) {
          completeRegister(ysselectedmenu.value);
        }
      }
    });

    const formSubmit = () => {
      Swal.fire({
        text: "Kayıt Başarılı",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Anladım",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        // router.push({ name: "sign-in" });
        // hideModal(createAppModalRef.value);
      });
    };

    resetForm({
      values: {
        ...formData.value,
      },
    });

    return {
      ysmenulist,
      trendyolbuttonhidden,
      ysselectedmenu,
      yshidemenu,
      token,
      completeRegister,
      branchindexTy,
      branchesYs,
      tempdatas,
      branchindexYs,
      branchHideYs,
      selectedBranchIndex,
      handleStep,
      branchHideGetir,
      branchindexGetir,
      branchesGetir,
      branchesTy,
      randompassword,
      hide,
      formSubmit,
      previousStep,
      nextStep,
      createAppRef,
      currentStepIndex,
      totalSteps,
      createAppModalRef,
      getIllustrationsPath,
      tybranchdata,
      pleaseWait
    };
  },
});
