
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import popup from "@/components/modals/wizards/CreateAppModal.vue";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-up",
  components: {
    //Field,
    popup,
    //Form,
    //ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const PasswordButton = ref<HTMLInputElement | null>(null);
    const hidden = ref(false);
    const registration = Yup.object().shape({
      first_name: Yup.string().required().label("Name"),
      last_name: Yup.string().required().label("Surname"),
      phone: Yup.number().required().label("phone"),
    });

    onMounted(() => {
      console.log("onMounted");
    });
    const onSubmitRegister = async (values) => {
      console.log(values);
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      await store.dispatch(Actions.Register_User, values).then((r: any) => {
        console.log(r);
        if (r == true) {
          Swal.fire({
            title: "Hata",
            text: "Bu telefon numarası sistemde kayıtlıdır.",
            icon: "error",
            confirmButtonText: "Tamam",
          }).then((result) => {
            router.push({ name: "sign-in" });
          });
        } else {
          var randompassword = Math.random().toString(36).slice(-6);
          var temp = Object.assign(values, {
            password: randompassword,
            Register_Date:
              new Date().getDay() +
              "-" +
              new Date().getMonth() +
              "-" +
              new Date().getFullYear(),
          });
        }
      });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      hidden,
      onSubmitRegister,
      submitButton,
      PasswordButton,
    };
  },
});
